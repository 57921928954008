import { Button, Spin, Table, Modal, Select, Tooltip, Badge } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import CardInfo from '../dashboard/components/CardInfo';
import useAsset from '../../hooks/useAsset';
import {
  IsCurrentTimestampIncludeInCustomRange,
  generateTimestampArray,
  latToDMS,
  longToDMS,
  roundTimestampsToNearestMinute,
  setCurrentTimezone,
  toHHMMSS,
  toKM,
  toKnot,
  toMiles,
  toNM,
} from '../../helpers/dateUtil';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import { useLocation, useSearchParams } from 'react-router-dom';
import useFilter from '../../hooks/useFilter';
import { ContainerLoading, LoadingWrapper } from '.';
import {
  DownloadOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  EyeOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import { intervalOptions } from '../../helpers/map';
import * as XLSX from 'xlsx';
import { degreesToRadians } from '../../helpers/map-util';
import DataCompletionInfo from '../../components/DataCompletionInfo';
import {
  getSource,
  getTimezone,
  proconCustomCalculation,
} from '../../helpers/asset';
import axios from 'axios';
import { useVT } from 'virtualizedtableforantd4';
import PopupDownload from './components/PopupDownload';
import StatusTag from '../../components/StatusTag';
import { getFromIndexedDB, saveToIndexedDB } from '../../helpers/indexedDB';

const windowHeight = window.innerHeight;

const ReportLog: React.FC = () => {
  const [summary, setSummary] = useState<any[]>([]);
  const {
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAssetSummary,
    dataCompletion,
    currentDataSource,
    handleOnDownloadPDF,
    currentDataTimezone,
  } = useAsset();
  const { dataFilter } = useFilter();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  // massid dari kapal kapal citra
  // digunakan untuk hide/show column location
  // dan juga untuk melimit function gmaps api
  const citraMassId: string[] = ['32', '33', '62', '61', '83', '86', '88'];

  // function untuk mengecek tipe kapal untuk mengatur hide/show column table
  const getType = (massId: string): string | undefined => {
    const typeData: { [key: string]: string[] } = {
      // 4 Flowmater
      'type-1': ['30', '31', '43', '49'],
      // 2 Flowmater
      'type-2': ['32', '33', '91', '95', '100', '83', '86', '88', '97'],
      'type-3': ['84', '61', '62'],
      // 2 Flowmater 1 engine
      'type-4': ['42'],
      // ems 2 engine
      EMS: [
        '6',
        '13',
        '15',
        '19',
        '20',
        '21',
        '23',
        '25',
        '27',
        '28',
        '51',
        '64',
        '65',
        '81',
        '82',
        '92',
        '101',
        '104',
        '105',
      ],
      // ems 3 engine
      'EMS-3e': ["103"]
    };

    for (const typeName in typeData) {
      if (typeData.hasOwnProperty(typeName)) {
        if (typeData[typeName].includes(massId)) {
          return typeName;
        }
      }
    }

    return 'no-type';
  };

  const { activeCompany, fetchCompanyData } = useCompany();
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine || false;
  const isEMS = currAsset?.isEMS || false;
  const massType = getType(currAsset ? currAsset.massId : '0');
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (dataFetchedCompRef.current) return;
    dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : getSource() === 'satellite'
          ? intervalOptions[1].value
          : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : dataFilter.massId
          ? dataFilter.massId
          : 'initial',
      aggregatedLength: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSource, currentDataTimezone]);

  const [dataTables, setDataTables] = useState<any>([]);
  const { isLoadingFilter } = useFilter();
  const [unit, setUnit] = useState<'KM' | 'NM' | 'MILES'>('NM');
  const [speed, setSpeed] = useState<'KNOT' | 'KM/H'>('KNOT');
  const [flowUnit, setFlowUnit] = useState<'lph' | 'lpm'>('lph');
  const [unitDistance, setUnitDistance] = useState<'M' | 'NM' | 'KM'>('NM');
  const [unitSpeed, setUnitSpeed] = useState<'KNOT' | 'KM/H'>('KNOT');
  const [summaryDistance, setSummaryDistance] = useState<'M' | 'NM' | 'KM'>(
    'NM'
  );

  const interval = dataFilter.interval;
  const getFlowUnit = (interval: string) => {
    if (interval === 'MINUTE') {
      return 'L/min';
    }
    return 'L/h';
  };


  const baseColumns: any = [
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: 180,
      fixed: 'left',
      align: 'center',
      // sorter: (a: any, b: any) => {
      //   if (sortDirection == 'descending') {
      //     return a.dateTime.localeCompare(b.dateTime);
      //   } else if (sortDirection == 'ascending') {
      //     return b.dateTime.localeCompare(a.dateTime);
      //   }
      // },
      // defaultSortOrder: "ascend"
    },
    {
      title: 'Coordinate',
      dataIndex: 'coordinate',
      key: 'coordinate',
      width: 210,
      align: 'center',
      render: (coordinate: string) => {
        if (!coordinate || typeof coordinate === 'undefined') {
          return '-';
        }
        const currValue = coordinate.split(' ');
        if (currValue.length === 2) {
          return `${latToDMS(currValue[0])}, ${longToDMS(currValue[1])}`;
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'locationName',
      width: 185,
      align: 'center',
      render: (value: any) => (typeof value === 'undefined' ? '-' : value),
      hide:
        dataFilter.interval === 'HOUR' &&
          citraMassId.includes(dataFilter.massId)
          ? false
          : true,
    },
    {
      title: 'Heading',
      dataIndex: 'heading',
      key: 'heading',
      width: 80,
      align: 'center',
      render: (value: any) =>
        typeof value === 'undefined'
          ? '-'
          : `${degreesToRadians(Number(value)).toFixed(2)} °`,
    },
    {
      title: 'Speed (KNOT)',
      dataIndex: 'speed',
      key: 'speed',
      width: 80,
      align: 'center',
      render: (value: any) => {
        if (typeof value === 'undefined') return '-';

        value = parseFloat(value)
        if (value < 0) { // jika value negatif maka di jadikan positif
          value = -value
        }

        return value;
      },
    },
    {
      title: 'Cruise (NM)',
      dataIndex: 'distance',
      key: 'distance',
      width: 80,
      align: 'center',
      render: (value: any) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
    },
    {
      title: isSingleEngine ? 'MAIN ENGINE' : 'PORT',
      dataIndex: 'port',
      key: 'port',
      align: 'center',
      children: [
        {
          title: 'RPM (RPM)',
          dataIndex: 'portRPM',
          key: 'portRPM',
          width: 100,
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: false,
        },
        {
          title: 'Status',
          dataIndex: 'portStatus',
          key: 'portStatus',
          width: 60,
          align: 'center',
          render: (value: any) =>
            value === 'ON' ? (
              <StatusTag dataStatus={'Online'} customText='ON' />
            ) : (
              <StatusTag dataStatus={'Offline'} customText='OFF' />
            ),
          hide: !isEMS,
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'portOpsMode',
          key: 'portOpsMode',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : value,
          hide:
            !currAsset?.isHaveOperationMode || dataFilter.interval !== 'MINUTE',
        },
        {
          title: 'Run. Hours (hh:mm:ss)',
          dataIndex: 'portRunningHour',
          key: 'portRunningHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'portFuelCons',
          key: 'portFuelCons',
          width: 100,
          align: 'center',
          render: (value: any, record: any) => {
            return typeof value === 'undefined'
              ? '-'
              : parseFloat(value).toFixed(2);
          },
          hide: currAsset?.isHaveOperationMode
            ? dataFilter.interval !== 'MINUTE'
            : isEMS,
        },
        {
          title: 'Volume Total In (Liter)',
          dataIndex: 'portInVolumeTotal',
          key: 'portInVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: `IN Flow (${getFlowUnit(interval)})`,
          dataIndex: 'portInFlow',
          key: 'portInFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          // render: (value: string) =>  typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
        },
        {
          title: 'Volume Total Out (Liter)',
          dataIndex: 'portOutVolumeTotal',
          key: 'portOutVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: `OUT Flow (${getFlowUnit(interval)})`,
          dataIndex: 'portOutFlow',
          key: 'portOutFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: 'IN Temp. (°C)',
          dataIndex: 'portInTemp',
          key: 'portInTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: 'OUT Temp. (°C)',
          dataIndex: 'portOutTemp',
          key: 'portOutTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: (
            <span>
              IN Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'portInDens',
          key: 'portInDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: (
            <span>
              OUT Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'portOutDens',
          key: 'portOutDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
      ],
    },
    {
      title: 'Center',
      dataIndex: 'ctr',
      key: 'ctr',
      hide: !currAsset?.isThreeEngine,
      align: 'center',
      children: [
        {
          title: 'RPM (RPM)',
          dataIndex: 'ctrRPM',
          key: 'ctrRPM',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: false,
        },
        {
          title: 'Status',
          dataIndex: 'ctrStatus',
          key: 'ctrStatus',
          width: 60,
          align: 'center',
          render: (value: any) =>
            value === 'ON' ? (
              <StatusTag dataStatus={'Online'} customText='ON' />
            ) : (
              <StatusTag dataStatus={'Offline'} customText='OFF' />
            ),
          hide: !isEMS,
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'ctrOpsMode',
          key: 'ctrOpsMode',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : value,
          hide: !currAsset?.isHaveOperationMode || dataFilter.interval !== 'MINUTE',
        },
        {
          title: 'Run. Hours (hh:mm:ss)',
          dataIndex: 'ctrRunningHour',
          key: 'ctrRunningHour',
          width: 100,
          align: 'center',
          render: (value: any) => value || null,
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ctrFuelCons',
          key: 'ctrFuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide:
            (!currAsset?.isHaveOperationMode ||
              dataFilter.interval !== 'MINUTE') ??
            isEMS,
        },
        {
          title: 'Volume Total In (Liter)',
          dataIndex: 'ctrInVolumeTotal',
          key: 'ctrInVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: `IN Flow (${getFlowUnit(interval)})`,
          dataIndex: 'ctrInFlow',
          key: 'ctrInFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          hide: isEMS,
        },
        {
          title: 'Volume Total Out (Liter)',
          dataIndex: 'ctrOutVolumeTotal',
          key: 'ctrOutVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: `OUT Flow (${getFlowUnit(interval)})`,
          dataIndex: 'ctrOutFlow',
          key: 'ctrOutFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: 'IN Temp. (°C)',
          dataIndex: 'ctrInTemp',
          key: 'ctrInTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: 'OUT Temp. (°C)',
          dataIndex: 'ctrOutTemp',
          key: 'ctrOutTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: (
            <span>
              IN Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'ctrInDens',
          key: 'ctrInDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: (
            <span>
              OUT Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'ctrOutDens',
          key: 'ctrOutDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
      ],
    },
    {
      title: 'STARBOARD',
      dataIndex: 'stb',
      key: 'stb',
      hide: isSingleEngine ? true : false,
      align: 'center',
      children: [
        {
          title: 'RPM (RPM)',
          dataIndex: 'stbRPM',
          key: 'stbRPM',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: false,
        },
        {
          title: 'Status',
          dataIndex: 'stbStatus',
          key: 'stbStatus',
          width: 60,
          align: 'center',
          render: (value: any) =>
            value === 'ON' ? (
              <StatusTag dataStatus={'Online'} customText='ON' />
            ) : (
              <StatusTag dataStatus={'Offline'} customText='OFF' />
            ),
          hide: !isEMS,
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'stbOpsMode',
          key: 'stbOpsMode',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : value,
          hide: !currAsset?.isHaveOperationMode || dataFilter.interval !== 'MINUTE',
        },
        {
          title: 'Run. Hours (hh:mm:ss)',
          dataIndex: 'stbRunningHour',
          key: 'stbRunningHour',
          width: 100,
          align: 'center',
          render: (value: any) => value || null,
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'stbFuelCons',
          key: 'stbFuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: currAsset?.isHaveOperationMode
            ? dataFilter.interval !== 'MINUTE'
            : isEMS,
        },
        {
          title: 'Volume Total In (Liter)',
          dataIndex: 'stbInVolumeTotal',
          key: 'stbInVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        // {
        //   title: 'IN Flow (L/h)',
        //   dataIndex: 'stbInFlow',
        //   key: 'stbInFlow',
        //   width: 100,
        //   align: 'center',
        //   render: (value: string) =>
        //     typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        //   hide: isEMS,
        // },
        {
          title: `IN Flow (${getFlowUnit(interval)})`,
          dataIndex: 'stbInFlow',
          key: 'stbInFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          hide: isEMS,
        },
        {
          title: 'Volume Total Out (Liter)',
          dataIndex: 'stbOutVolumeTotal',
          key: 'stbOutVolumeTotal',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: `OUT Flow (${getFlowUnit(interval)})`,
          dataIndex: 'stbOutFlow',
          key: 'stbOutFlow',
          width: 100,
          align: 'center',
          render: (value: any) => {
            if (typeof value === 'undefined') return '-';
            return interval === 'MINUTE' ?
              `${(value / 60).toFixed(2)}`
              : parseFloat(value).toFixed(2);
          },
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: 'IN Temp. (°C)',
          dataIndex: 'stbInTemp',
          key: 'stbInTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: 'OUT Temp. (°C)',
          dataIndex: 'stbOutTemp',
          key: 'stbOutTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
        {
          title: (
            <span>
              IN Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'stbInDens',
          key: 'stbInDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS,
        },
        {
          title: (
            <span>
              OUT Density (g/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'stbOutDens',
          key: 'stbOutDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: isEMS
            ? true
            : massType === 'type-2' || massType === 'type-3'
              ? true
              : false,
        },
      ],
    },
    {
      title: 'ME Fuel Cons. (Liter)',
      dataIndex: 'meFuelCons',
      key: 'meFuelCons',
      width: 150,
      align: 'center',
      render: (value: string) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
      hide: false,
    },
    {
      title: 'AE1',
      dataIndex: 'ae1',
      key: 'ae1',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae1RunHour',
          key: 'ae1RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae1FuelCons',
          key: 'ae1FuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: currMassId === '43',
        },
      ],
      hide: massType === 'type-4' ? true : false,
    },
    {
      title: 'AE2',
      dataIndex: 'ae2',
      key: 'ae2',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae2RunHour',
          key: 'ae2RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae2FuelCons',
          key: 'ae2FuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: currMassId === '43',
        },
      ],
      hide: massType === 'type-4' ? true : false,
    },
    {
      title: 'AE3',
      dataIndex: 'ae3',
      key: 'ae3',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae3RunHour',
          key: 'ae3RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae3FuelCons',
          key: 'ae3FuelCons',
          width: 100,
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: currMassId === '43',
        },
      ],
      hide: massType === 'type-4' ? true : false,
    },
    {
      title: 'AE4',
      dataIndex: 'ae4',
      key: 'ae4',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae4RunHour',
          key: 'ae4RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
          hide: false,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae4FuelCons',
          key: 'ae4FuelCons',
          width: 100,
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
          hide: currMassId === '43',
        },
      ],
      hide: activeCompany?.compId !== '39',
    },
    {
      title: 'AE Fuel Cons. (Liter)',
      dataIndex: 'aeFuelCons',
      key: 'aeFuelCons',
      width: 150,
      align: 'center',
      render: (value: string) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
      hide: currMassId !== '43',
    },
    {
      title: 'Total Fuel Cons. (Liter)',
      dataIndex: 'totalFuelCons',
      key: 'totalFuelCons',
      width: 100,
      align: 'center',
      render: (value: string) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
      hide: false,
    },
  ]
    .filter((item) => !item.hide)
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: item.children.filter((child: any) => !child.hide),
        };
      } else {
        return item;
      }
    });

  const [tableColumns, setTableColumns] = useState<any>([]);
  const [isColumnFilterModalOpen, setIsColumnFilterModalOpen] =
    useState<boolean>(false);

  const [checkedColumns, setCheckedColumns] = useState<any>([]);

  useEffect(() => {
    const loadColumns = async () => {
      const localFilterColumn = await getFromIndexedDB(
        `${massType}-filterColumn`
      );
      const savedColumns: any = localFilterColumn
        ? localFilterColumn
        : undefined;
      const currentColumns: any = baseColumns.map((item: any) => {
        if (item.children) {
          return {
            index: item.dataIndex,
            title: item.title,
            checked: true,
            children: item.children.map((data: any) => {
              return {
                index: data.dataIndex,
                title: item.title + ' ' + data.title,
                checked: true,
              };
            }),
          };
        } else {
          return {
            index: item.dataIndex,
            title: item.title,
            checked: true,
          };
        }
      });

      if (savedColumns) {
        const comparedColumns = currentColumns.map((item: any) => {
          const findcolumn = savedColumns.find(
            (itm: any) => itm.index === item.index
          );
          if (findcolumn) {
            if (item.children) {
              const childrenColumn = item.children.map((chldItm: any) => {
                const findChildColumn = findcolumn.children.find(
                  (childItm: any) => childItm.index === chldItm.index
                );
                if (findChildColumn) {
                  return { ...chldItm, checked: findChildColumn.checked };
                }
                return chldItm;
              });
              return {
                ...item,
                children: childrenColumn,
                checked: findcolumn.checked,
              };
            }
            return { ...item, checked: findcolumn.checked };
          }
          return item;
        });
        setCheckedColumns(comparedColumns);
      } else {
        setCheckedColumns(currentColumns);
      }
    };

    loadColumns();
  }, [dataFilter]);

  const handleCheckboxChange = async (index: string) => {
    const updatedData = checkedColumns.map((item: any) => {
      if (item.index === index) {
        if (item.children) {
          const updatedChildren = item.children.map((child: any) => {
            return { ...child, checked: !child.checked };
          });
          return { ...item, checked: !item.checked, children: updatedChildren };
        }
        return { ...item, checked: !item.checked };
      } else if (item.children) {
        const updatedChildren = item.children.map((child: any) => {
          if (child.index === index) {
            return { ...child, checked: !child.checked };
          }
          return child;
        });
        return { ...item, children: updatedChildren };
      }
      return item;
    });

    saveToIndexedDB(`${massType}-filterColumn`, updatedData);
    setCheckedColumns(updatedData);
  };

  const renderCheckbox = (data: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showChildren, setShowChildren] = useState<any>({});

    const handleToggleChildren = (index: any) => {
      setShowChildren({
        ...showChildren,
        [index]: !showChildren[index],
      });
    };

    return data.map((item: any, index: any) => {
      if (item.index === 'dateTime') return;
      return (
        <CheckboxItem key={index}>
          <div>
            <CheckboxInput
              type='checkbox'
              id={item.index}
              name={item.index}
              checked={item.checked}
              onChange={() => handleCheckboxChange(item.index)}
            />
            <CheckboxLabel htmlFor={item.index}>{item.title}</CheckboxLabel>
            {item.children && (
              <DropdownIndicator
                onClick={() => handleToggleChildren(item.index)}
              >
                <CaretDownOutlined
                  rotate={showChildren[item.index] ? 180 : 0}
                />
              </DropdownIndicator>
            )}
          </div>

          <div>
            {item.children &&
              showChildren[item.index] &&
              item.children.map((childItem: any, childIndex: any) => (
                <CheckboxChildItem key={childIndex}>
                  <CheckboxInput
                    type='checkbox'
                    id={childItem.index}
                    name={childItem.index}
                    checked={childItem.checked}
                    onChange={() => handleCheckboxChange(childItem.index)}
                  />
                  <CheckboxLabel htmlFor={childItem.index}>
                    {childItem.title}
                  </CheckboxLabel>
                </CheckboxChildItem>
              ))}
          </div>
        </CheckboxItem>
      );
    });
  };

  const filterColumn = (base: any, filter: any) => {
    return base.filter((item: any) => {
      const indexInfo = filter.find((el: any) => el.index === item.dataIndex);
      if (indexInfo && indexInfo.checked) {
        if (item.children && indexInfo.children) {
          item.children = filterColumn(item.children, indexInfo.children);
        }
        return true;
      }
      return false;
    });
  };

  const handleOpenColumnFilterModal = () => {
    setIsColumnFilterModalOpen(true);
  };

  const handleCancelForm = () => {
    setIsColumnFilterModalOpen(false);
  };

  useEffect(() => {
    let newColumns: any = filterColumn(baseColumns, checkedColumns);
    setTableColumns(newColumns);
  }, [checkedColumns]);

  function convertData(data: any, convertToNull?: boolean) {
    if (typeof data === 'number' || data === 0) {
      return data;
    } else {
      return !convertToNull ? undefined : null;
    }
  }

  // mengambil data nama lokasi dengan reverse geo code dari api gmaps
  const getLocationFromLatLong = async (lat: number, long: number) => {
    if (citraMassId.includes(dataFilter.massId)) {
      const reverseGeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GMAPS_API_KEY}&language=id&ocean=true`;

      try {
        const response = await axios.get(reverseGeoCodeUrl);
        const addressComponent = response.data.results[0].address_components;
        let formattedAddress = '-';

        if (addressComponent[1] && addressComponent[2]) {
          formattedAddress =
            addressComponent[1]?.short_name +
            ', ' +
            addressComponent[2]?.short_name;
        } else {
          // formattedAddress = addressComponent[0]?.short_name;
          const fetchLocation = await fetch(
            `/geonames/findNearbyJSON?lat=${lat}&lng=${long}&username=rgibranz_dev`
          );
          const location = await fetchLocation.json();
          formattedAddress = location?.geonames[0].toponymName;
        }

        return formattedAddress;
      } catch (error) {
        console.error('Error fetching reverse geocode:', error);
      }
    }
  };

  const handleUnitDistance = (unit: 'KM' | 'NM' | 'M') => {
    setUnitDistance(unit);

    setTableColumns(
      tableColumns.map((item: any) => {
        if (item.dataIndex === 'distance') {
          return { ...item, title: `Distance (${unit})` };
        }
        return item;
      })
    );
  };

  const handleUnitSpeed = (unit: 'KNOT' | 'KM/H') => {
    setUnitSpeed(unit);

    setTableColumns(
      tableColumns.map((item: any) => {
        if (item.dataIndex === 'speed') {
          return { ...item, title: `Speed (${unit})` };
        }
        return item;
      })
    );
  };


  const timezone = useMemo(() => {
    return setCurrentTimezone(currentDataTimezone);
  }, [currentDataTimezone]);

  useEffect(() => {
    if (currAsset && currMassId && currAsset.dataSummary) {
      const generateDataLog = async () => {
        
        const start = moment(dataFilter.range.startAt).tz(timezone).unix();
        const end = moment(dataFilter.range.endAt).tz(timezone).unix();

        let newCategories: any[] = generateTimestampArray(
          start,
          end,
          dataFilter.interval === 'MINUTE'
            ? 60
            : dataFilter.interval === 'HOUR'
              ? 3600
              : 86400
          // currentDataTimezone
        );

        /**
         * Di interval hari data kedua dst. akan dijadikan jam 0
         */
        if (dataFilter.interval === 'DAY') {
          newCategories = newCategories.map((timestamp, index) => {
            if (index !== 0) {
              return moment(timestamp * 1000)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .valueOf() / 1000;
            }
            return timestamp;
          });
        }

        /**
         * Di interval jam data terakhir akan di bulatkan ke menit 0
         */
        if (dataFilter.interval === 'HOUR') {
          const lastTimestamp = newCategories[newCategories.length - 1];
          newCategories[newCategories.length - 1] =
            Math.floor(lastTimestamp / 3600) * 3600;
        }

        const isHourInterval = dataFilter.interval === 'HOUR';
        const source = getSource();
        

        const dataTablesPromises = newCategories.map(async (timestamp) => {
          const gps = currAsset.dataSummary?.gps?.data.find((item) => {
            const found =
              timestamp ===
              roundTimestampsToNearestMinute(item.timestamp * 1000) / 1000;

            if (!found && isHourInterval) {
              return IsCurrentTimestampIncludeInCustomRange({
                timestamp,
                currentTimestamp: item.timestamp,
                dataItem: item,
              });
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          const singleEngineRPM = currAsset.dataSummary?.rpm?.data.find(
            (item) => {
              if (isSingleEngine) {
                let found = false;
                let type = 'MAINENGINE';
                if (
                  item['MAINENGINE'] &&
                  item['MAINENGINE'].timestamp &&
                  roundTimestampsToNearestMinute(
                    item['MAINENGINE'].timestamp * 1000
                  ) /
                  1000 ===
                  timestamp
                ) {
                  found = true;
                } else if (
                  item['PORT'] &&
                  item['PORT'].timestamp &&
                  roundTimestampsToNearestMinute(
                    item['PORT'].timestamp * 1000
                  ) /
                  1000 ===
                  timestamp
                ) {
                  found = true;
                  type = 'PORT';
                } else if (
                  item['STARBOARD'] &&
                  item['STARBOARD'] &&
                  roundTimestampsToNearestMinute(
                    item['STARBOARD'].timestamp * 1000
                  ) /
                  1000 ===
                  timestamp
                ) {
                  found = true;
                  type = 'STARBOARD';
                }

                if (!found && isHourInterval) {
                  return IsCurrentTimestampIncludeInCustomRange({
                    timestamp,
                    currentTimestamp: item[type].timestamp,
                    dataItem: item,
                  });
                } else if (found) {
                  return item;
                } else {
                  return undefined;
                }
              }
            }
          );

          const portRPM = currAsset.dataSummary?.rpm?.data.find((item) => {
            const found =
              item['PORT'] &&
              item['PORT'].timestamp &&
              roundTimestampsToNearestMinute(item['PORT'].timestamp * 1000) /
              1000 ===
              timestamp;

            if (!found && isHourInterval) {
              if (item['PORT']) {
                return IsCurrentTimestampIncludeInCustomRange({
                  timestamp,
                  currentTimestamp: item['PORT'].timestamp,
                  dataItem: item,
                });
              } else {
                return undefined;
              }
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          const stbRPM = currAsset.dataSummary?.rpm?.data.find((item) => {
            const found =
              item['STARBOARD'] &&
              item['STARBOARD'].timestamp &&
              roundTimestampsToNearestMinute(
                item['STARBOARD'].timestamp * 1000
              ) /
              1000 ===
              timestamp;

            if (!found && isHourInterval) {
              if (item['STARBOARD']) {
                return IsCurrentTimestampIncludeInCustomRange({
                  timestamp,
                  currentTimestamp: item['STARBOARD'].timestamp,
                  dataItem: item,
                });
              } else {
                return undefined;
              }
            } else if (found) {
              return found;
            } else {
              return undefined;
            }
          });

          const ctrRPM = currAsset.dataSummary?.rpm?.data.find((item) => {
            const found =
              item['CENTER'] &&
              item['CENTER'].timestamp &&
              roundTimestampsToNearestMinute(
                item['CENTER'].timestamp * 1000
              ) /
              1000 ===
              timestamp;

            if (!found && isHourInterval) {
              if (item['CENTER']) {
                return IsCurrentTimestampIncludeInCustomRange({
                  timestamp,
                  currentTimestamp: item['CENTER'].timestamp,
                  dataItem: item,
                });
              } else {
                return undefined;
              }
            } else if (found) {
              return found;
            } else {
              return undefined;
            }
          });

          const flowmeter = currAsset.dataSummary?.flowmeter?.data.find(
            (item) => {
              let found = false;
              if (
                item.PORT_IN &&
                item.PORT_IN.timestamp &&
                roundTimestampsToNearestMinute(item.PORT_IN.timestamp * 1000) /
                1000 ===
                timestamp
              ) {
                found = true;
              } else if (
                item.PORT_OUT &&
                item.PORT_OUT.timestamp &&
                roundTimestampsToNearestMinute(item.PORT_OUT.timestamp * 1000) /
                1000 ===
                timestamp
              ) {
                found = true;
              }

              if (!found && isHourInterval) {
                return IsCurrentTimestampIncludeInCustomRange({
                  timestamp,
                  currentTimestamp: item.PORT_IN.timestamp,
                  dataItem: item,
                });
              } else if (found) {
                return item;
              } else {
                return undefined;
              }
            }
          );

          const ae1 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            let found = false;
            if (
              item.AE1 &&
              item.AE1.timestamp &&
              roundTimestampsToNearestMinute(item.AE1.timestamp * 1000) /
              1000 ===
              timestamp
            ) {
              found = true;
            }

            if (!found && isHourInterval) {
              return IsCurrentTimestampIncludeInCustomRange({
                timestamp,
                currentTimestamp: item.AE1?.timestamp,
                dataItem: item,
              });
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          const ae2 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            let found = false;
            if (
              item.AE2 &&
              item.AE2.timestamp &&
              roundTimestampsToNearestMinute(item.AE2.timestamp * 1000) /
              1000 ===
              timestamp
            ) {
              found = true;
            }

            if (!found && isHourInterval) {
              return IsCurrentTimestampIncludeInCustomRange({
                timestamp,
                currentTimestamp: item.AE2?.timestamp,
                dataItem: item,
              });
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          const ae3 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            let found = false;
            if (
              item.AE3 &&
              item.AE3.timestamp &&
              roundTimestampsToNearestMinute(item.AE3.timestamp * 1000) /
              1000 ===
              timestamp
            ) {
              found = true;
            }

            if (!found && isHourInterval) {
              return IsCurrentTimestampIncludeInCustomRange({
                timestamp,
                currentTimestamp: item.AE3?.timestamp,
                dataItem: item,
              });
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          const ae4 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            let found = false;
            if (
              item.AE4 &&
              item.AE4.timestamp &&
              roundTimestampsToNearestMinute(item.AE4.timestamp * 1000) /
              1000 ===
              timestamp
            ) {
              found = true;
            }

            if (!found && isHourInterval) {
              return IsCurrentTimestampIncludeInCustomRange({
                timestamp,
                currentTimestamp: item.AE4?.timestamp,
                dataItem: item,
              });
            } else if (found) {
              return item;
            } else {
              return undefined;
            }
          });

          let portRunningHour = '-';

          if (
            isSingleEngine &&
            singleEngineRPM &&
            Object.values(singleEngineRPM)[0]
          ) {
            if (Object.values(singleEngineRPM)[0].runningTime) {
              portRunningHour = toHHMMSS(Object.values(singleEngineRPM)[0].runningTime);
            } else {
              portRunningHour = toHHMMSS(0);
            }
          } else if (
            portRPM?.PORT?.runningTime &&
            convertData(portRPM?.PORT?.runningTime)
          ) {
            portRunningHour = toHHMMSS(portRPM?.PORT?.runningTime);
          } else if (portRPM?.PORT?.rpm === 0) {
            portRunningHour = toHHMMSS(0);
          }

          // menghitung manual stb cons utk kapal citra 02
          const citra02stbFuelCons = () => {
            let flowrate =
              isHourInterval && source === 'gsm'
                ? flowmeter?.STARBOARD_IN?.additional?.AVG_dlfmVolFlowrate_2
                : flowmeter?.STARBOARD_InFlow ?? 0 / 60;

            return isHourInterval ? flowrate : Number(flowrate ?? 0) / 60;
          };
          // menghitung manual port cons utk kapal citra 02
          const citra02portFuelCons = () => {
            let flowrate =
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_IN?.additional?.AVG_dlfmVolFlowrate_2
                : flowmeter?.PORT_InFlow ?? 0 / 60;

            return isHourInterval ? flowrate : Number(flowrate ?? 0) / 60;
          };

          let locationName = undefined;

          if (gps && isHourInterval) {
            locationName = await getLocationFromLatLong(
              gps?.additional?.LAST_dlgpLatitude_4,
              gps?.additional?.LAST_dlgpLongitude_5
            );
          }

          let distance = convertData(
            isHourInterval && source === 'gsm'
              ? gps?.additional?.SUM_dlgpDistance_3
              : gps?.distance
          );

          let speed = convertData(
            isHourInterval && source === 'gsm'
              ? gps?.additional?.AVG_dlgpSpeed_0
              : gps?.speed
          );

          let constanta = 0;

          if (currentDataTimezone && currentDataTimezone > 7) {
            constanta = (currentDataTimezone - 7) * 60 * 60;
          }

          const newDateTime = timestamp + constanta;

          const aeFuelCons = convertData(ae1?.AE1 ? ae1?.AE1?.fuelConsumption : 0) +
            convertData(ae1?.AE2 ? ae2?.AE2.fuelConsumption : 0) +
            convertData(ae1?.AE3 ? ae3?.AE3?.fuelConsumption : 0) +
            convertData(ae1?.AE4 ? ae4?.AE4?.fuelConsumption : 0);

          const operationMode =
            currAsset?.operationModeSeries?.data[timestamp] ?? undefined;
          const operationModeTotalCons =
            (operationMode?.STARBOARD
              ? parseFloat(operationMode.STARBOARD.value)
              : 0) +
            (operationMode?.PORT ? parseFloat(operationMode.PORT.value) : 0);

          const totalFuelCons = currAsset?.isHaveOperationMode
            ? operationModeTotalCons + aeFuelCons
            : convertData(flowmeter ? flowmeter?.ME_FuelCons : 0) + aeFuelCons;

          return {
            id: timestamp,
            // GPS
            dateTime: moment(newDateTime * 1000).format(
              'DD MMM YYYY, HH:mm:ss'
            ),
            coordinate: gps
              ? `${isHourInterval && source === 'gsm'
                ? gps?.additional?.LAST_dlgpLatitude_4
                : gps.latitude
              } ${isHourInterval && source === 'gsm'
                ? gps?.additional?.LAST_dlgpLongitude_5
                : gps.longitude
              }`
              : undefined,
            locationName: locationName,
            speed:
              speed || speed === 0
                ? unitSpeed === 'KNOT'
                  ? toKnot(speed)
                  : speed.toFixed(2)
                : undefined,
            distance:
              distance || distance === 0
                ? unitDistance === 'NM'
                  ? toNM(distance)
                  : unitDistance === 'KM'
                    ? toKM(distance)
                    : distance.toFixed(2)
                : undefined,
            heading: convertData(
              isHourInterval && source === 'gsm'
                ? gps?.additional?.AVG_dlgpTrack_1
                : gps?.track
            ),
            // PORT/SINGLE ENGINE
            portRPM:
              isSingleEngine && singleEngineRPM
                ? isHourInterval && source === 'gsm'
                  ? Object.values(singleEngineRPM)[0].additional.AVG_dlrpRpm_0
                  : Object.values(singleEngineRPM)[0].rpm
                : isHourInterval && source === 'gsm'
                  ? convertData(portRPM?.PORT?.additional?.AVG_dlrpRpm_0)
                  : convertData(portRPM?.PORT?.rpm),
            portStatus:
              isSingleEngine && singleEngineRPM
                ? isHourInterval && source === 'gsm'
                  ? Object.values(singleEngineRPM)[0].additional.AVG_dlrpRpm_0 >
                    0
                    ? 'ON'
                    : 'OFF'
                  : Object.values(singleEngineRPM)[0].rpm > 0
                    ? 'ON'
                    : 'OFF'
                : isHourInterval && source === 'gsm'
                  ? convertData(portRPM?.PORT?.additional?.AVG_dlrpRpm_0) > 0
                    ? 'ON'
                    : 'OFF'
                  : convertData(portRPM?.PORT?.rpm) > 0
                    ? 'ON'
                    : 'OFF',
            portRunningHour,
            portOpsMode: operationMode?.PORT?.mode,
            portFuelCons:
              currAsset?.massId === '62' // jika kapal citra02 menghitung manual fuelcons
                ? citra02portFuelCons()
                : currAsset.isHaveOperationMode
                ? operationMode?.PORT?.value
                  ? parseFloat(operationMode?.PORT?.value).toFixed(2)
                  : 0
                : isSingleEngine && flowmeter
                ? convertData(flowmeter?.ME_FuelCons)
                : convertData(flowmeter?.PORT_FuelCons),
            portInVolumeTotal: convertData(flowmeter?.PORT_IN?.volumeTotal),
            portInFlow: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_IN?.additional?.AVG_dlfmVolFlowrate_2
                : flowmeter?.PORT_InFlow
            ),
            portOutVolumeTotal: convertData(flowmeter?.PORT_OUT?.volumeTotal),
            portOutFlow: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_OUT?.additional?.AVG_dlfmVolFlowrate_2
                : flowmeter?.PORT_OutFlow
            ),
            portInTemp: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_IN?.additional?.AVG_dlfmTemp_6
                : flowmeter?.PORT_IN?.temperature
            ),
            portOutTemp: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_OUT?.additional?.AVG_dlfmTemp_6
                : flowmeter?.PORT_OUT?.temperature
            ),
            portInDens: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_IN?.additional?.AVG_dlfmDensity_3
                : flowmeter?.PORT_InDens
            ),
            portOutDens: convertData(
              isHourInterval && source === 'gsm'
                ? flowmeter?.PORT_OUT?.additional?.AVG_dlfmDensity_3
                : flowmeter?.PORT_OutDens
            ),
            // STARBOARD ENGINE
            stbRPM: isSingleEngine
              ? undefined
              : isHourInterval && source === 'gsm'
                ? convertData(stbRPM?.STARBOARD?.additional?.AVG_dlrpRpm_0)
                : convertData(stbRPM?.STARBOARD?.rpm),
            stbStatus: isSingleEngine
              ? undefined
              : isHourInterval && source === 'gsm'
                ? convertData(stbRPM?.STARBOARD?.additional?.AVG_dlrpRpm_0) > 0
                  ? 'ON'
                  : 'OFF'
                : convertData(stbRPM?.STARBOARD?.rpm) > 0
                  ? 'ON'
                  : 'OFF',
            stbRunningHour: isSingleEngine
              ? undefined
              : toHHMMSS(
                stbRPM?.STARBOARD?.rpm === 0
                  ? 0
                  : convertData(stbRPM?.STARBOARD?.runningTime)
              ),
            stbOpsMode: operationMode?.STARBOARD?.mode,
            stbFuelCons:
              currAsset?.massId === '62' // jika kapal citra02 menghitung manual fuelcons
                ? citra02stbFuelCons()
                : currAsset.isHaveOperationMode
                ? operationMode?.PORT?.value
                  ? parseFloat(operationMode?.PORT?.value).toFixed(2)
                  : 0
                : isSingleEngine
                ? undefined
                : convertData(flowmeter?.STARBOARD_FuelCons),
            stbInVolumeTotal: isSingleEngine
              ? undefined
              : flowmeter?.STARBOARD_IN?.volumeTotal,
            stbInFlow: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_IN?.additional?.AVG_dlfmVolFlowrate_2
                  : flowmeter?.STARBOARD_InFlow
              ),
            stbOutVolumeTotal: isSingleEngine
              ? undefined
              : flowmeter?.STARBOARD_OUT?.volumeTotal,
            stbOutFlow: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_OUT?.additional
                    ?.AVG_dlfmVolFlowrate_2
                  : flowmeter?.STARBOARD_OutFlow
              ),
            stbInTemp: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_IN?.additional?.AVG_dlfmTemp_6
                  : flowmeter?.STARBOARD_IN?.temperature
              ),
            stbOutTemp: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_OUT?.additional?.AVG_dlfmTemp_6
                  : flowmeter?.STARBOARD_OUT?.temperature
              ),
            stbInDens: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_IN?.additional?.AVG_dlfmDensity_3
                  : flowmeter?.STARBOARD_InDens
              ),
            stbOutDens: isSingleEngine
              ? undefined
              : convertData(
                isHourInterval && source === 'gsm'
                  ? flowmeter?.STARBOARD_OUT?.additional?.AVG_dlfmDensity_3
                  : flowmeter?.STARBOARD_OutDens
              ),

            // CENTER ENGINE
            ctrRPM: convertData(ctrRPM?.CENTER?.rpm),
            ctrStatus:
              isHourInterval && source === 'gsm'
                ? convertData(ctrRPM?.CENTER?.additional?.AVG_dlrpRpm_0) > 0
                  ? 'ON'
                  : 'OFF'
                : convertData(ctrRPM?.CENTER?.rpm) > 0
                ? 'ON'
                : 'OFF',
            ctrRunningHour: toHHMMSS(convertData(ctrRPM?.CENTER?.runningTime)),
            ctrOpsMode: operationMode?.CENTER?.mode,

            meFuelCons: currAsset.isHaveOperationMode
              ? operationModeTotalCons
              : flowmeter
              ? convertData(flowmeter.ME_FuelCons)
              : undefined,
            aeFuelCons:
              flowmeter && flowmeter.AE_FuelConsVolume
                ? convertData(flowmeter.AE_FuelConsVolume)
                : undefined,
            // AE
            ae1RunHour:
              ae1?.AE1?.runningSeconds || ae1?.AE1?.runningSeconds === 0
                ? toHHMMSS(ae1?.AE1?.runningSeconds)
                : undefined,
            ae1FuelCons: convertData(ae1?.AE1?.fuelConsumption),
            ae2RunHour:
              ae2?.AE2?.runningSeconds || ae2?.AE2?.runningSeconds === 0
                ? toHHMMSS(ae2?.AE2?.runningSeconds)
                : undefined,
            ae2FuelCons: convertData(ae2?.AE2.fuelConsumption),
            ae3RunHour:
              ae3?.AE3?.runningSeconds || ae3?.AE3?.runningSeconds === 0
                ? toHHMMSS(ae3?.AE3?.runningSeconds)
                : undefined,
            ae3FuelCons: convertData(ae3?.AE3?.fuelConsumption),
            ae4RunHour:
              ae4?.AE4?.runningSeconds || ae4?.AE4?.runningSeconds === 0
                ? toHHMMSS(ae4?.AE4?.runningSeconds)
                : undefined,
            ae4FuelCons: convertData(ae4?.AE4?.fuelConsumption),
            totalFuelCons,
          };
        });

        try {
          let dataTables = await Promise.all(dataTablesPromises);

          setDataTables(dataTables);
        } catch (error) {
          console.error('Error generating dataTables:', error);
        }
      };

      generateDataLog();
      generateSummary();
    }

    // eslint-disable-next-spline react-hooks/exhaustive-deps
  }, [currAsset, currMassId, unitDistance, unitSpeed]);

  const handleToggleData = (groupId: string) => {
    const newSummary = summary.map((item: any) =>
      item.groupId === groupId
        ? {
          ...item,
          show: !item.show,
        }
        : {
          ...item,
        }
    );
    setSummary(newSummary);
  };

  const proconDeviceData = useMemo(() => {
    const flowmeterData = proconCustomCalculation(
      currAsset?.dataSummary?.flowmeter?.data,
      dataFilter.interval
    );
    return {
      portAverageFlow: flowmeterData.portAverageFlow,
      starboardAverageFlow: flowmeterData.starboardAverageFlow,
      portTotalFuelCons: flowmeterData.portTotalFuelCons,
      starboardTotalFuelCons: flowmeterData.starboardTotalFuelCons,
    };
  }, [currAsset]);

  const generateSummary = () => {
    let currSummary: any = [];
    if (currAsset && currAsset.dataSummary) {
      const data = currAsset.dataSummary;
      currSummary = [
        {
          groupId: '0',
          show: true,
          contents: [
            {
              hide: false,
              label: 'General Summary',
              datas: [
                {
                  label: 'Total Cruise',
                  value: data.gps
                    ? unit === 'KM'
                      ? toKM(data.gps.summary.totalDistance)
                      : unit === 'MILES'
                        ? toMiles(data.gps.summary.totalDistance)
                        : toNM(data.gps.summary.totalDistance)
                    : '-',
                  unit: unit,
                  icon: 'marker',
                  options: [
                    {
                      value: 'KM',
                      label: 'KM',
                    },
                    {
                      value: 'NM',
                      label: 'NM',
                    },
                    {
                      value: 'MILES',
                      label: 'MILES',
                    },
                  ],
                  handle: (val: any) => {
                    setUnit(val);
                  },
                },
                {
                  label: 'Average Speed',
                  value: data.gps
                    ? speed === 'KNOT'
                      ? toKnot(data.gps.summary.averageSpeed)
                      : data.gps.summary.averageSpeed.toFixed(2)
                    : '-',
                  unit: speed,
                  options: [
                    { value: 'KM/H', label: 'KM/H' },
                    { value: 'KNOT', label: 'KNOT' },
                  ],
                  handle: (val: any) => {
                    setSpeed(val);
                  },
                  icon: 'marker',
                },
                // {
                //   label: "Average Speed",
                //   value: toKnot(data.gps.calculatedSpeedKMh),
                //   unit: speed,
                //   icon: "marker",
                // },
                {
                  label: 'Distance',
                  value: data.gps
                    ? summaryDistance === 'KM'
                      ? toKM(data.gps.summary.distance)
                      : summaryDistance === 'NM'
                        ? toNM(data.gps.summary.distance)
                        : data.gps.summary.distance.toFixed(2)
                    : '-',
                  unit: summaryDistance,
                  icon: 'marker',
                  options: [
                    { value: 'M', label: 'M' },
                    { value: 'NM', label: 'NM' },
                    { value: 'KM', label: 'KM' },
                  ],
                  handle: (val: any) => {
                    setSummaryDistance(val);
                  },
                },
              ],
            },
          ],
        },
        {
          groupId: '1',
          show: false,
          contents: [
            {
              hide: false,
              label: 'Average RPM',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value: data.rpm?.summary?.average?.MAINENGINE?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ]
                : currAsset.isThreeEngine
                ? [
                    {
                      label: 'PORT',
                      value: data.rpm?.summary?.average.PORT?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                    {
                      label: 'CENTER',
                      value: data.rpm?.summary?.average?.CENTER?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm?.summary?.average?.STARBOARD?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ]
                  : [
                    {
                      label: 'PORT',
                      value: data.rpm?.summary.average?.PORT?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm?.summary.average?.STARBOARD?.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ],
            },
            {
              hide: false,
              label: 'Running Hour',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value: data.rpm
                        ? toHHMMSS(
                            data.rpm.summary.runningTime.PORT ||
                              data.rpm.summary.runningTime.STARBOARD ||
                              data.rpm.summary.runningTime.MAINENGINE ||
                              0
                          )
                        : '-',
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ]
                : currAsset.isThreeEngine
                ? [
                    {
                      label: 'PORT',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.PORT)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                    {
                      label: 'CENTER',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.CENTER)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.STARBOARD)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                  ]
                : [
                    {
                      label: 'PORT',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.PORT)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.STARBOARD)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                  ],
            },
            {
              hide: isEMS,
              label: 'Average Flow',
              datas: isSingleEngine
                ? [
                  {
                    label: 'MAIN ENGINE',
                    value:
                      data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined &&
                        data.flowmeter.summary.portEngineCons !== 0 &&
                        data.rpm?.summary.runningTime &&
                        Object.keys(data.rpm.summary.runningTime).length > 0 &&
                        data.rpm.summary.runningTime.PORT !== 0
                        ? data.flowmeter.summary.portAverageFlow.toFixed(2)
                        : 0,
                    unit: 'Liter',
                    icon: 'marker',
                  },
                ]
                : [
                    // ==== port average flow ====
                    {
                      label: 'PORT',
                      value:
                        currAsset?.massId === '62' // jika kapal citra02 menghitung manual fuelcons
                          ? proconDeviceData.portAverageFlow
                          : data.flowmeter &&
                            data.flowmeter.summary &&
                            data.flowmeter.summary.portAverageFlow &&
                            data.flowmeter.summary.portAverageFlow !==
                              undefined &&
                            data.flowmeter.summary.portAverageFlow !== 0
                          ? data.flowmeter.summary.portAverageFlow.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                    // ==== starboard average flow ====
                    {
                      label: 'STARBOARD',
                      value:
                        currAsset?.massId === '62' // jika kapal citra02 menghitung manual fuelcons
                          ? proconDeviceData.starboardAverageFlow
                          : data.flowmeter &&
                            data.flowmeter.summary &&
                            data.flowmeter.summary.starboardAverageFlow &&
                            data.flowmeter.summary.starboardAverageFlow !==
                              undefined &&
                            data.flowmeter.summary.starboardAverageFlow !== 0
                          ? data.flowmeter.summary.starboardAverageFlow.toFixed(
                              2
                            )
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ],
            },
            {
              hide: isEMS,
              label: 'Total Engine Cons.',
              datas: isSingleEngine
                ? [
                  {
                    label: 'MAIN ENGINE',
                    value:
                      data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined
                        ? data.flowmeter.summary.portEngineCons.toFixed(2)
                        : 0,
                    unit: 'Liter',
                    icon: 'marker',
                  },
                ]
                : [
                    {
                      label: 'PORT',
                      value:
                        currAsset?.massId === '62'
                          ? proconDeviceData.portTotalFuelCons
                          : data.flowmeter &&
                            data.flowmeter.summary &&
                            data.flowmeter.summary &&
                            data.flowmeter.summary.portEngineCons &&
                            data.flowmeter.summary.portEngineCons !== undefined
                          ? data.flowmeter.summary.portEngineCons.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value:
                        currAsset?.massId === '62'
                          ? proconDeviceData.starboardTotalFuelCons
                          : data.flowmeter &&
                            data.flowmeter.summary &&
                            data.flowmeter.summary.starboardEngineCons &&
                            data.flowmeter.summary.starboardEngineCons !==
                              undefined
                          ? data.flowmeter.summary.starboardEngineCons.toFixed(
                              2
                            )
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ],
            },
          ],
        },
        {
          groupId: '2',
          show: false,
          contents: [
            {
              hide: false,
              label: 'AE1',
              datas: [
                {
                  label: 'Running Time',
                  value:
                    data.ae?.summary && data.ae.summary.runningSeconds
                      ? toHHMMSS(data.ae.summary.runningSeconds.AE1)
                      : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE1?.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
            {
              hide: false,
              label: 'AE2',
              datas: [
                {
                  label: 'Running Time',
                  value: data.ae?.summary.runningSeconds
                    ? toHHMMSS(data.ae.summary.runningSeconds.AE2)
                    : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE2?.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
            {
              hide: false,
              label: 'AE3',
              datas: [
                {
                  label: 'Running Time',
                  value: data.ae?.summary.runningSeconds
                    ? toHHMMSS(data.ae.summary.runningSeconds.AE3)
                    : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE3?.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
          ],
        },
      ];

      if (dataFilter.compId === '39') {
        currSummary[2].contents.push({
          hide: activeCompany?.compId !== '39',
          label: 'AE4',
          datas: [
            {
              label: 'Running Time',
              value: data.ae?.summary.runningSeconds
                ? toHHMMSS(data.ae.summary.runningSeconds.AE4)
                : 0,
              icon: 'marker',
            },
            {
              label: 'Fuel Consumption',
              value: data.ae?.summary.fuelConsumption
                ? data.ae.summary.fuelConsumption.AE4?.toFixed(2)
                : 0,
              unit: 'Liter',
              icon: 'marker',
            },
          ],
        });
      }
    }

    if (isEMS) {
      setSummary(
        currSummary.map((item: any) => ({
          ...item,
          contents: item.contents.filter((item: any) => !item.hide),
        }))
      );
    } else {
      setSummary(currSummary);
    }
  };

  useEffect(() => {
    generateSummary();
  }, [unit, speed, summaryDistance]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const handleDownloadExcel = () => {
    const start = moment(dataFilter.range.startAt).format(
      'DD_MM_YYYY_HH_mm_ss'
    );
    const end = moment(dataFilter.range.endAt).format('DD_MM_YYYY_HH_mm_ss');
    const range_excel = start + '-' + end;

    const EMSnotIncludeKey = [
      'portInVolumeTotal',
      'portInFlow',
      'portOutVolumeTotal',
      'portOutFlow',
      'portInTemp',
      'portOutTemp',
      'portInDens',
      'portOutDens',
      'stbInVolumeTotal',
      'stbInFlow',
      'stbOutVolumeTotal',
      'stbOutFlow',
      'stbInTemp',
      'stbOutTemp',
      'stbInDens',
      'stbOutDens',
    ];

    let notIncludeKey = [
      'id',
      'stbStatus',
      'portStatus',
      citraMassId.includes(dataFilter.massId) && dataFilter.interval === 'HOUR'
        ? ''
        : 'locationName',
    ];

    if (activeCompany?.compId !== '39') {
      notIncludeKey.push('ae4RunHour', 'ae4FuelCons');
    }

    if (!currAsset?.isHaveOperationMode) {
      notIncludeKey.push('portOpsMode', 'stbOpsMode', 'ctrOpsMode');
    }

    if (currAsset?.isHaveOperationMode && dataFilter.interval !== 'MINUTE') {
      notIncludeKey.push('portFuelCons', 'ctrFuelCons', 'stbFuelCons');
    }

    if (!currAsset?.isThreeEngine) {
      notIncludeKey.push(
        'ctrRPM',
        'ctrStatus',
        'ctrOpsMode',
        'ctrRunningHour',
        'ctrFuelCons',
        'ctrInVolumeTotal',
        'ctrInFlow',
        'ctrOutVolumeTotal',
        'ctrOutFlow',
        'ctrInTemp',
        'ctrOutTemp'
      );
    }

    if (currAsset?.isThreeEngine && currAsset?.isHaveOperationMode) {
      notIncludeKey.push(
        'ctrInVolumeTotal',
        'ctrInFlow',
        'ctrOutVolumeTotal',
        'ctrOutFlow',
        'ctrInTemp',
        'ctrOutTemp'
      );
    }

    checkedColumns.forEach((val: any) => {
      if (!val.children) {
        if (!val.checked) {
          notIncludeKey.push(val.index);
        }
      } else {
        val.children.forEach((childVal: any) => {
          if (!childVal.checked) {
            notIncludeKey.push(childVal.index);
          }
        });
      }
    });

    const newRefinedData = dataTables.map((item: any) => {
      const newItem = {} as any;
      Object.keys(item)
        .filter((key) => !notIncludeKey.includes(key))
        .filter((key) => (isEMS ? !EMSnotIncludeKey.includes(key) : true))
        .filter((item) =>
          massType === 'type-2' || massType === 'type-3'
            ? !item.includes('Out')
            : true
        )
        .forEach((key) => {
          if (key === 'coordinate') {
            let latitude = item[key] ? item[key].split(' ')[0] : '';
            let longitude = item[key] ? item[key].split(' ')[1] : '';
            newItem['latitude'] = latToDMS(latitude);
            newItem['longitude'] = longToDMS(longitude);
          } else if (
            key === 'timestamp' ||
            key === 'dateTime' ||
            key === 'locationName' ||
            key === 'portOpsMode' ||
            key === 'stbOpsMode' ||
            typeof item[key] === 'string'
          ) {
            newItem[key] = item[key];
          } else if (typeof item[key] === 'number') {
            newItem[key] = parseFloat(item[key].toFixed(2));
          } else {
            newItem[key] = 0;
          }
        });

      // ubah judul kolom dari camelcase menjadi biasa/dengan spasi
      const changedKeys: any = {};
      for (const key in newItem) {
        if (newItem.hasOwnProperty(key)) {
          const words = key.split(
            /(?<=[a-z0-9])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z0-9])/
          );
          const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
          const newKey = capitalizedWords.join(' ');
          changedKeys[newKey] = newItem[key];
        }
      }
      return changedKeys;
    });

    // hitung panjang kolom
    let colsLength = newRefinedData.reduce((acc: any, row: any) => {
      let values = Object.values(row);

      values.forEach((value: any, index) => {
        if (index === 0 || index === 1 || index === 2) {
          acc[index] = Math.max(acc[index] || 0, value ? value.length : 0);
        } else if (
          citraMassId.includes(dataFilter.massId) &&
          dataFilter.interval === 'HOUR' &&
          index === 3
        ) {
          // untuk kolom locationName
          acc[index] = Math.max(acc[index] || 0, value ? value.length : 0);
        } else {
          acc[index] = 15;
        }
      });

      return acc;
    }, []);

    // Membuat array wscols dengan panjang kolom yang sudah dihitung
    let wscols = colsLength.map((width: any) => ({ width: width }));

    const data = XLSX.utils.json_to_sheet(newRefinedData);
    data['!cols'] = wscols; //set panjang kolom
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(
      wb,
      `${currAsset?.massName.split(' ').join('_')}-${range_excel}.xlsx`
    );
  };

  const location = useLocation();
  const [vt, set_components] = useVT(
    () => ({ scroll: { y: windowHeight - 300, x: 700 } }),
    []
  );

  const downloadItems = useMemo(() => {
    if (currAsset) {
      return [
        {
          key: 'summary',
          label: 'Summary',
        },
        {
          key: 'vessel_tracking',
          label: 'Vessel Tracking',
        },
        {
          key: 'rpm_speed_ae',
          label: currAsset?.isEMS
            ? 'RPM vs Speed vs AE Running Time'
            : 'RPM vs Fuel Cons. vs Speed vs AE Running Time',
        },
        {
          key: 'me_fuel_consumption',
          label: 'ME Fuel Consumption',
          hide: currAsset?.isEMS,
        },
        {
          key: 'me_fuel_consumption_running',
          label: 'ME Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || !currAsset?.isSingleEngine,
        },
        {
          key: 'port_fuel_consumption_running',
          label: 'PORT Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || currAsset?.isSingleEngine,
        },
        {
          key: 'starboard_fuel_consumption_running',
          label: 'STARBOARD Fuel Used vs Running Hours',
          hide: currAsset?.isEMS || currAsset?.isSingleEngine,
        },
        {
          key: 'ae_fuel_consumption ',
          label: 'AE Fuel Consumption',
          hide: currAsset?.isSingleEngine,
        },
        {
          key: 'operation_mode',
          label: 'Operation Mode',
          hide: !currAsset.isHaveOperationMode,
        },
      ].filter((item) => !item.hide);
    }
  }, [currAsset]);

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <ReportActionContent>
        <FilterDashboard
          isLog
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
        {/* <Button
          loading={isLoadingAssetSummary}
          disabled={isLoadingAssetSummary && dataTables.length === 0}
          onClick={downloadExcel}
          size="small"
          style={{
            color: "white",
            marginBottom: 8,
            background: "#54C95A",
          }}
          title="Download XLSX"
        >
          Download XlSX
        </Button> */}
        <PopupDownload
          options={downloadItems || []}
          loading={isLoadingAssetSummary}
          onDownload={(e) => handleOnDownloadPDF(currAsset, e)}
          isReportLog
          onDownloadXLSX={handleDownloadExcel}
        />
      </ReportActionContent>

      {isLoadingAssetSummary || isLoadingFilter ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : dataTables && dataTables.length > 0 && summary.length > 0 ? (
        <Content>
          {summary.map((group: any) => (
            <ContentWrapper odd={group.groupId === '0'} key={group.groupId}>
              <HandleAccordion onClick={() => handleToggleData(group.groupId)}>
                {group.show ? <MinusOutlined /> : <PlusOutlined />}
              </HandleAccordion>
              {group.contents.map((content: any) => (
                <Wrapper key={content.label}>
                  <ContentItem>
                    <h3 onClick={() => handleToggleData(group.groupId)}>
                      {content.label}
                    </h3>
                    {group.groupId === '0' ? (
                      <FlexRow show={group.show}>
                        {content.datas.map((data: any, idx: number) => (
                          <CardInfo
                            key={idx}
                            label={data.label}
                            value={data.value}
                            iconType='marker'
                            dropdown={true}
                            unit={data.unit || undefined}
                            options={data.options || undefined}
                            onChange={data.handle || undefined}
                          />
                        ))}
                      </FlexRow>
                    ) : (
                      <FlexColumn show={group.show}>
                        {content.datas.map((data: any, idx: number) => (
                          <CardInfo
                            key={idx}
                            label={data.label}
                            value={data.value}
                            iconType='marker'
                            unit={data.unit || undefined}
                          />
                        ))}
                      </FlexColumn>
                    )}
                  </ContentItem>
                </Wrapper>
              ))}
            </ContentWrapper>
          ))}

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
            <Button
              icon={<EyeOutlined />}
              style={{ background: '#0957C2', color: '#FFFFFF' }}
              onClick={handleOpenColumnFilterModal}
              size='small'
            >
              Show/Hide Column
            </Button>

            <div style={{ marginLeft: 15, fontWeight: 'bold' }}>
              <label htmlFor=''>Distance Unit :</label>
              <Select
                size='small'
                defaultValue={unitDistance}
                style={{ width: 120, marginLeft: 10 }}
                onChange={handleUnitDistance}
                options={[
                  { value: 'M', label: 'M' },
                  { value: 'NM', label: 'NM' },
                  { value: 'KM', label: 'KM' },
                ]}
              />
            </div>

            <div style={{ marginLeft: 15, fontWeight: 'bold' }}>
              <label htmlFor=''>Speed Unit :</label>
              <Select
                size='small'
                defaultValue={unitSpeed}
                style={{ width: 120, marginLeft: 10 }}
                onChange={handleUnitSpeed}
                options={[
                  { value: 'KNOT', label: 'KNOT' },
                  { value: 'KM/H', label: 'KM/H' },
                ]}
              />
            </div>
          </div>

          <Table
            className='custom-small-table'
            style={{ marginTop: 5 }}
            size='small'
            bordered
            columns={tableColumns}
            pagination={{
              pageSize: 500,
            }}
            scroll={{ y: windowHeight - 300, x: 700 }}
            dataSource={dataTables}
            components={vt}
          />
        </Content>
      ) : (
        <ContainerLoading>
          <LoadingWrapper>
            <InfoCircleOutlined />
            <div>Data not found</div>
          </LoadingWrapper>
        </ContainerLoading>
      )}

      <Modal
        title='Show/Hide Table Column'
        open={isColumnFilterModalOpen}
        destroyOnClose
        onCancel={handleCancelForm}
        className='custom-modal'
        footer={null}
      >
        <CheckboxContainer>{renderCheckbox(checkedColumns)}</CheckboxContainer>
      </Modal>
    </React.Fragment>
  );
};

export default ReportLog;

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: flex-end;
  padding-right: 20px;
`;

const HandleAccordion = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
`;

const Content = styled.div`
  padding: 20px 20px;
  height: auto;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

interface ContentWrapperProps {
  odd: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Dark};
  padding: 10px 0;
  display: grid;
  gap: 20px 15px;
  position: relative;
  cursor: pointer;
  grid-template-columns: ${(props) => (props.odd ? '1fr' : 'repeat(4, 1fr)')};
`;

const Wrapper = styled.div``;

const ContentItem = styled.div`
  h3 {
    font-weight: 600;
    margin: 0;
  }
`;

interface FlexProps {
  show: boolean;
}

const FlexColumn = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 15px;
`;

const FlexRow = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'grid' : 'none')};
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`;

const CheckboxItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxChildItem = styled.div`
  margin-left: 15px;
  max-width: 100%;
  overflow: scroll;
  flex-direction: column;
`;

const DropdownIndicator = styled.span<any>`
  cursor: pointer;
  transform: ${(props) => (props.rotate ? 'rotate(0)' : 'rotate(90)')};
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  font-weight: normal;
`;
